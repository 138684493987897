import { Typography, TextField, Select, MenuItem, Button, CircularProgress, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useMemo, useRef, useState, useEffect } from "react";
import { createUploadTemplatingForm, make_request } from "../../helpers.js";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import "./UploadTemplatingForm.scss";

export default function UploadingTemplatingForm(props) {
    const [cup, setCup] = useState("");
    const [head, setHead] = useState("");
    const [stem, setStem] = useState("");
    const [osteotomy, setOsteotomy] = useState("");
    const [remarks, setRemarks] = useState("");
    const [legLengthDiscrepancy, setLegLengthDiscrepancy] = useState("");
    const [apxray, setapxray] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTemplating();
    }, []);

    const getTemplating = async () => {
        const caseId = sessionStorage.getItem("upload-templating-caseID");
        const td = await make_request('/api/case/' + caseId + '/retrieve-templating', "GET");
        setCup(td['templatingdata']['templating_cup']);
        setHead(td['templatingdata']['templating_head']);
        setStem(td['templatingdata']['templating_stem']);
        setOsteotomy(td['templatingdata']['templating_osteotomy']);
        setRemarks(td['templatingdata']['templating_remarks']);
        setLegLengthDiscrepancy(td['templatingdata']['templating_leglength']);
        setapxray(td['ap_xray']);
        setLoading(false);
    }

    // image related
    const imgRef = useRef(null);
    const [img, setImg] = useState(null);

    const images = useMemo(() => {
        const url = img ? URL.createObjectURL(img) : ""
        return <div style={{ backgroundImage: `url(${url})` }} onClick={() => imgRef.current.click()} id="templating-image" className='image-holder'>
            {!img && <h4>Upload Image</h4>}
        </div>
    }, [img]);

    const disableBtn = () => {
        return img === null;
    } 
    const xrayisnull = () => {
        return apxray === null;
    }

    const downloadAP = () => {
        var windowReference = window.open();
        const opennewtab = async () => {
            windowReference.location = apxray;
        }
        return opennewtab()
    }

    const submitCase = async () => {
        const payload = {
            cup: cup,
            head: head,
            stem: stem,
            osteotomy: osteotomy,
            remarks: remarks,
            legLength: legLengthDiscrepancy,
            image: img,
        }

        setLoading(true);
        const caseId = sessionStorage.getItem("upload-templating-caseID");
        const res = await createUploadTemplatingForm(payload, caseId);
        setLoading(false);
        if (res.status === 201 || res.status === 200){
            props.closeDialog(true);
        } else{
            toast.warning("Fail to update the templating");
        }

    }

    return <div>
        <input ref={imgRef} style={{ display: "none" }} type="file" onChange={(e) => setImg(e.target.files[0])} />

        <form id='upload-templating-form'>
            <div>
                <h1>Templating Details</h1>
                <h3>All fields are mandatary except for remarks</h3>
            </div>

            <div id='upload-templating-divider'>
                {loading
                    ? <CircularProgress />
                    : <>
                        <div id='upload-templating-left'>
                            <div className='field-holder'>
                                <h2>Cup</h2>
                                <TextField value={cup} fullWidth size="small" onChange={(e) => setCup(e.target.value)} />
                            </div>
                            <div className='field-holder'>
                                <h2>Stem</h2>
                                <TextField value={stem} fullWidth size="small" onChange={(e) => setStem(e.target.value)} />
                            </div>
                            <div className='field-holder'>
                                <h2>Head</h2>
                                <TextField value={head} fullWidth size="small" onChange={(e) => setHead(e.target.value)} />
                            </div>
                            <div className='field-holder'>
                                <h2>Osteotomy</h2>
                                <TextField value={osteotomy} fullWidth size="small" onChange={(e) => setOsteotomy(e.target.value)} />
                            </div>
                            <div className='field-holder'>
                                <h2>Leg Length Discrepancy</h2>
                                <TextField value={legLengthDiscrepancy} fullWidth size="small" onChange={(e) => setLegLengthDiscrepancy(e.target.value)} />
                            </div>
                            <div>
                                <br/>
                                <Button sx={{ width: '100%' }} variant="outlined" disabled={xrayisnull() || loading} onClick={downloadAP}>Download AP X-ray</Button>
                            </div>
                        </div>
                        <div id='upload-templating-right'>
                            <div className='field-holder tall'>
                                <h2>Remarks</h2>
                                <textarea value={remarks} id="templating-remarks" onChange={(e) => setRemarks(e.target.value)} />
                            </div>
                            <div id='upload-templating-image-field'>
                                <h2>Templating</h2>
                                {images}
                            </div>
                        </div>
                        <AiOutlineCloseCircle onClick={() => props.closeDialog(false)} size={'1.8rem'} className='close-form-button' />
                    </>}
            </div>
            <Button sx={{ width: '10rem' }} variant="contained" disabled={disableBtn() || loading} onClick={submitCase}>Submit</Button>
        </form>
    </div>
}
