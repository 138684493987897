import "./CaseBar.scss";
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { make_request } from "../../helpers";
import Divider from '@mui/material/Divider';

export default function CaseBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isStaff = localStorage.getItem('is_staff') === 'true';
  const className = open ? "casebar clicked" : "casebar";
  const hasTemplate = props.data.report_templating !== "" ? true : false;
  const hasEssential = props.data.report_essential !== "" ? true : false;
  const editable = props.data.case_status == "Open" ? true : false;
  const nav = useNavigate();

  const getDateStr = (input) => {
    const date = new Date(Date.parse(input));
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    setAnchorEl(null);
  }

  const uploadTemplating = () => {
    props.openTemplatingDialog();
    setAnchorEl(null);
    sessionStorage.setItem("upload-templating-caseID", props.data.id);
  }

  const editCase = () => {
    props.openEditCaseDialog(props.data);
    setAnchorEl(null);
  }

  const downloadEssential = () => {
    if (props.data.report_essential !== "") {
      var windowReference = window.open();
      const opennewtab = async () => {
        const data = await make_request('/api/case/' + props.data.id, 'GET');
        const url = data.reportstorage.essential_report;
        windowReference.location = url;
        setAnchorEl(null);
      }
      return opennewtab()
    }
  }

  const downloadTemplating = () => {
    if (props.data.report_templating !== "") {
      var windowReference = window.open();
      const opennewtab = async () => {
        const data = await make_request('/api/case/' + props.data.id, 'GET');
        const url = data.reportstorage.templating_report;
        windowReference.location = url;
        setAnchorEl(null);
      }
      return opennewtab()
    }
  }

  const gotoAnnotatePage = () => {
    nav("/annotation-steps/" + props.data.id);
  }

  return <>
    <div
      className={className}
      aria-controls={open ? 'case-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    >
      <p className="hiddable">{props.data.firstname + " " + props.data.lastname}</p>
      <p className="hiddable">{props.data.side_of_analysis}</p>
      <p className="hiddable">{getDateStr(props.data.date_of_surgery)}</p>
      <p className="hiddable">{props.data.report_id}</p>
      <p className="hiddable">{props.data.case_status}</p>
      <p className="hiddable">{getDateStr(props.data.created)}</p>
      {/* <HiOutlineDotsCircleHorizontal
      className="case-actions"
      aria-controls={open ? 'case-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    /> */}

    </div>
    <Menu
      id="case-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      style={{
        transform: 'translateX(-2rem) translateY(0%)',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 0.2rem 0.5rem rgba(0,0,0,0.2))',
          mt: '0rem',
          borderRadius: '0.8rem',
          '.MuiMenuItem-root':{
            fontSize: '1.2rem',
            margin: '0.6rem 1rem 0.5rem 1rem',
          }
        },
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem disabled={!editable} onClick={editCase}>Edit</MenuItem>
      <MenuItem onClick={uploadTemplating}>Templating</MenuItem>
      <Divider />
      <MenuItem disabled={!hasEssential} sx={{ cursor: 'default', display: "flex", flexDirection: "column", alignItems: "start" }} onClick={downloadEssential}>
        <p>Download Report</p>
        <p style={{ fontSize: "0.8rem"}}>&#40;Essential&#41;</p>
      </MenuItem>
      <MenuItem disabled={!hasTemplate} sx={{ cursor: 'default', display: "flex", flexDirection: "column", alignItems: "start" }} onClick={downloadTemplating}>
        <p>Download Report</p>
        <p style={{ fontSize: "0.8rem"}}>&#40;Essential + Templating&#41;</p>
      </MenuItem>
      {isStaff && <Divider/>}
      {isStaff && <MenuItem onClick={gotoAnnotatePage}>Annotate Images</MenuItem>}
    </Menu>
  </>
}