import { Button, TextField, Box, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { fetchAccessToken, backendURL, make_request } from "../../helpers.js";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';                                                                   
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const STEP = {
    USERNAME: 1,
    PASSWORD: 2,
}

const progressStyle = {
    placeSelf: "center",
    color: '#4d4d4d',
}

const formStyle = {
    position: "absolute",
    right: "calc((50% - 32.5rem)/2)",
    bottom: "calc((100% - 25rem)/2)",
    display: "flex",
    justifyContent: "center"
}

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    rowGap: "1.5rem",
}

const btnStyle = {
    '&:disabled': {
        height: '3.9rem',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0 0.1rem 1rem',
        background: '#d6d6d6',
        borderRadius: '0.5rem',
        color:'grey',
        fontSize: '1.1rem',
    },
    '&:enabled': {
        height: '3.9rem',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0 0.1rem 1rem',
        background: '#4d4d4d',
        borderRadius: '0.5rem',
        color:'white',
        fontSize: '1.1rem',
    },
}

const headerStyle = {
    fontSize: "2.1rem",
    fontWeight: "400",
    margin: "0",
    color: "black"
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: '0.5rem',
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      width: '20rem',
      fontSize: '1.3rem',
      height: '2.9rem',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0 0.1rem 1rem',
      padding: '0.5rem 1rem 0.5rem 1rem',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      fontFamily: [
        'Montserrat',
        'sans-serif',
      ].join(','),
      '&:focus': {
        boxShadow: 'rgba(0, 0, 0, 0.15) 0 0 0 0.2rem',
        borderColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
  }));


export function Login(props) {
    const [username, setUsername] = useState(null);
    const [pwd, setPwd] = useState(null);
    const [step, setStep] = useState(STEP.USERNAME);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [btnText, setBtnText] = useState("Next");
    const [loading, setLoading] = useState(false);
    const navigator = useNavigate();

    const loginFromMemory = async () => {
        try {
            const accessToken = await fetchAccessToken();
            localStorage.setItem("accessToken", accessToken);
            navigator("/dashboard");
        } catch (error) {
            return;
        }
    }

    useEffect(() => {
        // auto login if possible
        // loginFromMemory();
    }, []);

    const nextStep = (event) => {
        event.preventDefault();
        if (step === STEP.USERNAME) {
            setStep(STEP.PASSWORD);
            setBtnText("SIGN IN")
        } else if (step === STEP.PASSWORD) {
            attemptLogin(username, pwd)
        }
    }

    const setBtn = (input) => {
        input.trim() === "" ? setBtnDisabled(true) : setBtnDisabled(false);
    }

    const attemptLogin = async (username, pwd) => {
        setLoading(true);
        const payload = {
            method: "POST",
            headers: { 'Content-Type': 'application/json', },
            body: JSON.stringify({
                email: username,
                password: pwd,
            })
        }
        const res = await fetch(backendURL + "/api/token/", payload)
        if (res.status === 401) {
            toast.error("Incorrect username or password");
            setStep(STEP.USERNAME);
        } else if (res.status === 200) {
            const load = await res.json()
            localStorage.setItem("accessToken", load.access);
            localStorage.setItem("refreshToken", load.refresh);

            const info = await make_request("/api/user/info", "GET");
            localStorage.setItem("title", info.title);
            localStorage.setItem("first_name", info.first_name);
            localStorage.setItem("last_name", info.last_name);
            localStorage.setItem("email", info.email);
            const initial = (info.last_name && typeof info.last_name === 'string' && info.last_name.length >= 1) ? info.last_name[0].toUpperCase() : 'N'
            localStorage.setItem("initial", initial);
            localStorage.setItem("is_staff", info.is_staff);
            localStorage.setItem("is_surgeon", info.is_surgeon);
            localStorage.setItem("is_rep", info.is_rep);
            localStorage.setItem("uid", info.id);
            localStorage.setItem("fullname", info.title + ' ' + info.first_name + ' ' + info.last_name);

            toast.success("Sign in successfully");
            navigator("/dashboard");
        }
        setLoading(false);
    }

    return (
        <form style={formStyle}>
            {loading
                ? <CircularProgress style={progressStyle}/>
                : <Box sx={containerStyle}>
                    <h1 style={headerStyle}>Welcome !</h1>
                    <span style={headerStyle}></span>
                    {step === STEP.USERNAME && <BootstrapInput

                        onChange={(e) => {
                            setBtn(e.target.value);
                            setUsername(e.target.value);
                        }}
                        autoFocus
                        id="username"
                        // hint="Username"
                        placeholder="Email"
                        type="text"
                    />}
                    {step === STEP.PASSWORD && <BootstrapInput
                        onChange={(e) => {
                            setBtn(e.target.value);
                            setPwd(e.target.value);
                        }}
                        autoFocus
                        id="password"
                        // hint="Password"
                        type="password"
                        placeholder="Password"
                    />}
                    <Button
                        type="submit"
                        sx={btnStyle}
                        disabled={btnDisabled}
                        size="large"
                        onClick={nextStep}
                        variant="contained"
                    >
                        {btnText}
                    </Button>
                </Box>
            }
        </form>
    )
}