import { useState } from 'react';
import { Button, CircularProgress, TextField, Typography} from "@mui/material";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { make_request } from "../../helpers";
import "./ChangePassword.scss";
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { toast } from 'react-toastify';

export default function ChangePasswordForm(props) {
  const [loading, setLoading] = useState(false);

  // Set up state to hold the form data
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Define a function to handle changes to the form fields
  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  }

  // Define a function to handle form submission
  async function handleSubmit(event) {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = formData;

    // Validate the form data
    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill in all fields");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password don't match");
      return;
    }
    setLoading(true);
    try {
      const body = {
                        old_password: currentPassword,
                        password: newPassword,
                        password2: confirmPassword,
                    }
      const response = await make_request("/api/user/change-password", "PUT", body);

      // Handle the response from the server
      if (response.status = 200) {
        // Password change was successful
        toast.success("Password changed successfully!");
        setLoading(false);
        props.closeDialog(true);
      } else {
        // Password change failed
        toast.error(response.detail);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error changing your password");
      setLoading(false);
    }
  }

  return (
    <form id='change-password-form'>
        <div>
            <h1>My Account</h1>
        </div>
        <br></br>
        <div className='form-row'>
                <h2>Email</h2>
                <TextField
                        size="small"
                        type="text"
                        id="email"
                        disabled
                        value={localStorage.getItem('email')}
                    />
            </div>
        {loading
            ? <CircularProgress />
            : <>
                <div className='form-row'>
                    <h2>Current Password</h2>
                    <TextField
                        size="small"
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        value={formData.currentPassword}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='form-row'>
                    <h2>New Password</h2>
                    <TextField
                        size="small"
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                    />
                </div>
                <div className='form-row'>
                    <h2>Confirm Password</h2>
                    <TextField
                        size="small"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                    />
                </div>
            </>
        }
        <br></br>
        <Button variant="contained" disabled={loading} onClick={handleSubmit}>Change Password</Button>
        <AiOutlineCloseCircle onClick={props.closeDialog} size={'1.8rem'} className='close-form-button' />
    </form>

  );
}