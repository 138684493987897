import { Typography, TextField, Select, MenuItem, Button, CircularProgress, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useMemo, useRef, useState, useEffect } from "react";
import { createForm, make_request } from "../../helpers.js";
import ImageHolder from "../imageHolder/ImageHolder.jsx";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import "./CreateCaseForm.scss";

export default function CreateCaseForm(props) {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [dateOfSurg, setDateOfSurg] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [surgeon, setSurgeon] = useState(null);
    const [surgicalApproach, setSurgicalApproach] = useState(null);
    const [gender, setGender] = useState("M");
    const [side, setSide] = useState("L");
    const [surgicalPosition, setSurgicalPosition] = useState("S");

    // progress indication
    const [loading, setLoading] = useState(false);

    // dynamic values loaded from backend
    const [surgeons, setSurgeons] = useState([]);
    const [allAppraoches, setAllAppraoches] = useState([]);

    const img1Ref = useRef(null);
    const img2Ref = useRef(null);
    const img3Ref = useRef(null);
    const img4Ref = useRef(null);
    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [img3, setImg3] = useState(null);
    const [img4, setImg4] = useState(null);
    const [minimumDate, setMinimumDate] = useState(new Date());
    const [maximumDate, setMaximumDate] = useState(new Date());
    const isSurgeon = localStorage.getItem("is_surgeon") === 'true';
    const uid = localStorage.getItem("uid");
    const fullname = localStorage.getItem("fullname");

    const images = useMemo(() => <div className='form-row'>
        <ImageHolder title="AP" image={img1} onClick={() => img1Ref.current.click()} />
        <ImageHolder title="Standing Lateral" image={img2} onClick={() => img2Ref.current.click()} />
        <ImageHolder title="Relax Seated Lateral" image={img3} onClick={() => img3Ref.current.click()} />
        <ImageHolder title="Flex Seated Lateral" image={img4} onClick={() => img4Ref.current.click()} />
    </div>, [img1, img2, img3, img4]);

    useEffect(() => {
        getSurgeons();
        getSurgicalApproaches();
        setMinimumDate(calculateMinimumDate());
    }, []);

    const submitCase = async () => {
        const payload = {
            firstname: firstname,
            lastname: lastname,
            dateOfBirth: dateOfBirth,
            gender: gender,
            sideOfAnalysis: side,
            surgicalApproach: surgicalApproach,
            surgicalPosition: surgicalPosition,
            dateOfSurgery: dateOfSurg,
            surgeon: surgeon,
            xrayAp: img1,
            xrayLst: img2,
        }

        if (img3) {
            payload["xrayLse"] = img3;
        }

        if (img4) {
            payload["xrayFlse"] = img4;
        }

        setLoading(true);
        const res = await createForm(payload);
        setLoading(false);
        if (res.status === 201 || res.status === 200){
            props.closeDialog(true);
        } else {
            toast.warning("Fail to create a case");
        }
    }

    const getSurgeons = async () => {
        if (isSurgeon){
            setSurgeon(uid)
        } else {
            const allSurgeons = await make_request("/api/user/surgeons/", "GET");
            // const allSurgeons = res.results;
            setSurgeons(allSurgeons);
            if (allSurgeons.length > 0) {
                setSurgeon(allSurgeons[0].id)
            } else {
                alert("No surgeons available");
            }
        }
    }

    const calculateMinimumDate = () => {
        const today = new Date();
        let count = 0;
      
        while (count < 2) {
          today.setDate(today.getDate() + 1); // Increment the date by one day
      
          // Skip weekends (Saturday and Sunday)
          if (today.getDay() !== 0 && today.getDay() !== 6) {
            count++;
          }
        }
      
        return today;
      };

    const getSurgicalApproaches = async () => {
        const approaches = await make_request("/api/surgicalapproach/", "GET");
        setAllAppraoches(approaches);
        setSurgicalApproach(approaches[0].name);
    }

    const disableBtn = () => {
        return (
            firstname === "" || lastname === ""
            || dateOfBirth === null || dateOfBirth === ""
            || dateOfSurg === null || dateOfSurg === ""
            || img1 === null || img2 === null
        )
    }

    const selectStyles = {
        MenuProps:{
            maxHeight: '15rem'
        } 
      };

    return <div>
        <input ref={img1Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg1(e.target.files[0])} />
        <input ref={img2Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg2(e.target.files[0])} />
        <input ref={img3Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg3(e.target.files[0])} />
        <input ref={img4Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg4(e.target.files[0])} />
        <form id='create-case-form'>
            <div>
                <h1 style={{fontSize:"1.7rem"}}>New case</h1>
                <h3 style={{fontWeight:300}}>All fields are mandatary</h3>
                <br></br>
            </div>
            {loading
                ? <CircularProgress />
                : <>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>First Name</h2>
                            <TextField fullWidth={true} size="small" onChange={(e) => setFirstname(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Last Name</h2>
                            <TextField fullWidth={true} size="small" onChange={(e) => setLastname(e.target.value)} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Date of Birth</h2>
                            <TextField
                                fullWidth={true}
                                type="date"
                                size="small"
                                inputProps={{
                                    max: maximumDate.toISOString().split('T')[0],
                                }}
                                onChange={(e) => setDateOfBirth(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Gender</h2>
                            <Select
                                fullWidth={true}
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="M">Male</MenuItem>
                                <MenuItem value="F">Female</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Date of Surgery</h2>
                            <TextField
                                fullWidth={true}
                                type="date"
                                size="small"
                                inputProps={{
                                    min: minimumDate.toISOString().split('T')[0],
                                }}
                                onChange={(e) => setDateOfSurg(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Surgeon</h2>
                            
                            <Select
                                fullWidth={true}
                                value={surgeon}
                                onChange={(e) => setSurgeon(e.target.value)}
                                size="small"
                                MenuProps={{
                                    style: {
                                       maxHeight: '20rem',
                                          },
                                    }}
                            >
                                {isSurgeon ? (
                                    <MenuItem key={uid} value={uid}>{fullname}</MenuItem>
                                ) : (
                                    surgeons.map(s => <MenuItem key={s.id} value={s.id}>{s.full_name}</MenuItem>)
                                )}
                            </Select>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Surgical Approach</h2>
                            <Select
                                fullWidth={true}
                                value={surgicalApproach}
                                onChange={(e) => setSurgicalApproach(e.target.value)}
                                size="small"
                            >
                                {allAppraoches.map((approach) => <MenuItem key={approach.name} value={approach.name}>{approach.name}</MenuItem>)}
                            </Select>
                        </div>
                        <div className='field-divider'>
                            <div className='field-holder'>
                                <h2>Surgical Position</h2>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        style={{height:"2.4rem"}}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={surgicalPosition}
                                        onChange={(event) => setSurgicalPosition(event.target.value)}
                                    >
                                        <FormControlLabel value="S" control={<Radio color='fourth' />} label={<Typography fontSize={"0.9rem"} fontWeight={surgicalPosition === "S" ? 600 : 500} color={surgicalPosition === "S" ? "black" : "grey"}>SUPINE</Typography>} />
                                        <FormControlLabel value="L" control={<Radio color= 'primary' />} label={<Typography fontSize={"0.9rem"} fontWeight={surgicalPosition === "L" ? 600 : 500} color={surgicalPosition === "L" ? "black" : "grey"}>LATERAL</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className='field-holder less'>
                                <h2>Side of Analysis</h2>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        style={{height:"2.4rem"}}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={side}
                                        onChange={(event) => setSide(event.target.value)}
                                    >
                                        <FormControlLabel value="L" control={<Radio color='third' />} label={<Typography fontSize={"0.9rem"} fontWeight={side === "L" ? 600 : 500} color={side === "L" ? "black" : "grey"}>LEFT</Typography>} />
                                        <FormControlLabel value="R" control={<Radio color='third' />} label={<Typography fontSize={"0.9rem"} fontWeight={side === "R" ? 600 : 500} color={side === "R" ? "black" : "grey"}>RIGHT</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {images}
                    <AiOutlineCloseCircle onClick={() => props.closeDialog(false)} size={'1.8rem'} className='close-form-button' />
                </>
            }
            <Button sx={{ width: '10rem'}} variant="contained" disabled={disableBtn() || loading} onClick={submitCase}>Submit</Button>
        </form>
    </div>
}
