import { Login } from "../../components/login/Login.jsx";
import "./LoginPage.scss";
import navbit from "../../assets/navbit_style2.svg";

const pageStyle = {
    height: "100vh",
    backgroundImage: "url('https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8d2FsbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8&w=1000&q=80') ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    position: "relative",
};

const logostyle = {
    position: "absolute",
    left: "calc((100% - 25rem)/2)",
    bottom: "calc((100% - 25rem)/2)",
    maxWidth: "25rem",
    minWidth: "15rem",
    width: "20%",
    display: "flex",
    justifyContent: "center",
};


export function LoginPage(props) {
    return (
        <div id="login-page">
            <navbitlogo style={logostyle}>
                <img src={navbit} alt="" id="navbit-logo" />
            </navbitlogo>
            <Login onSuccess={props.onSuccess} />
        </div>
    )
}