import axios from "axios";

export const backendURL = "https://archived.api.navbit.cloud:443";
// export const backendURL = "http://127.0.0.1:8000";
export const versionNo = "1.0.2"

export function clearLoginInfo() {
    localStorage.clear();
}

export async function fetchAccessToken() {
    const refreshToken = localStorage.getItem("refreshToken");

    // user must login if refresh token not found
    if (!refreshToken) {
        return Promise.reject("refresh token not found");
    }

    const payload = {
        method: "POST",
        timeout: 5000,
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ 'refresh': refreshToken })
    }
    const response = await fetch(backendURL + "/api/token/refresh/", payload);

    if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem('accessToken', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        return Promise.resolve(data.access);
    } else {
        return Promise.reject("refresh token expired");
    }
}

export async function createForm(payload) {
    const accessToken = localStorage.getItem("accessToken");
    let form_data = new FormData();
    form_data.append("patient.first_name", payload.firstname);
    form_data.append("patient.last_name", payload.lastname);
    form_data.append("patient.date_of_birth", payload.dateOfBirth);
    form_data.append("patient.gender", payload.gender);

    form_data.append("side_of_analysis", payload.sideOfAnalysis);
    form_data.append("surgical_position", payload.surgicalPosition);
    form_data.append("surgical_approach", payload.surgicalApproach);
    form_data.append("date_of_surgery", payload.dateOfSurgery);
    form_data.append("surgeon", payload.surgeon);

    form_data.append("imagingdata.xray_ap", payload.xrayAp);
    form_data.append("imagingdata.xray_lst", payload.xrayLst);

    if (payload.xrayLse) {
        form_data.append("imagingdata.xray_lse", payload.xrayLse);
    }
    if (payload.xrayFlse) {
        form_data.append("imagingdata.xray_lfse", payload.xrayFlse);
    }

    const res = await axios({
        method: 'POST',
        url: backendURL + '/api/case/create',
        data: form_data,
        headers: {
            "content-type": "multipart/form-data",
            'Authorization': "Bearer " + accessToken
        }
    });

    return res
}

export async function updateForm(payload, id) {
    const accessToken = localStorage.getItem("accessToken");
    let form_data = new FormData();
    form_data.append("patient.first_name", payload.firstname);
    form_data.append("patient.last_name", payload.lastname);
    form_data.append("patient.date_of_birth", payload.dateOfBirth);
    form_data.append("patient.gender", payload.gender);

    form_data.append("side_of_analysis", payload.sideOfAnalysis);
    form_data.append("surgical_position", payload.surgicalPosition);
    form_data.append("surgical_approach", payload.surgicalApproach);
    form_data.append("date_of_surgery", payload.dateOfSurgery);
    form_data.append("surgeon", payload.surgeon);
    form_data.append("scheme", payload.scheme);
    form_data.append("approval_no", payload.approval_no);
    form_data.append("imagingdata.null", {});

    if (payload.xrayAp) {
        form_data.append("imagingdata.xray_ap", payload.xrayLse);
    } if (payload.xrayLst) {
        form_data.append("imagingdata.xray_lst", payload.xrayLse);
    }
    if (payload.xrayLse) {
        form_data.append("imagingdata.xray_lse", payload.xrayLse);
    }
    if (payload.xrayFlse) {
        form_data.append("imagingdata.xray_lfse", payload.xrayFlse);
    }

    const res = await axios({
        method: 'PUT',
        url: backendURL + '/api/case/' + id + '/edit',
        data: form_data,
        headers: {
            "content-type": "multipart/form-data",
            'Authorization': "Bearer " + accessToken
        }
    });

    return res;
}

export async function createUploadTemplatingForm(payload, id) {
    const accessToken = localStorage.getItem("accessToken");
    let form_data = new FormData();
    form_data.append("templating_cup", payload.cup);
    form_data.append("templating_osteotomy", payload.osteotomy);
    form_data.append("templating_stem", payload.stem);
    form_data.append("templating_head", payload.head);
    form_data.append("templating_leglength", payload.legLength);
    form_data.append("templating_remarks", payload.remarks);
    form_data.append("templating_ap", payload.image);

    const res = await axios({
        method: 'PUT',
        url: backendURL + '/api/case/' + id + '/update-templating',
        data: form_data,
        headers: {
            "content-type": "multipart/form-data",
            'Authorization': "Bearer " + accessToken
        }
    });

    return res;
}

export async function fetchImage(url) {
    const payload = {
        method: 'GET',
    }

    const res = await fetch(url, payload);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    return imageObjectURL;
}


export function getImageOriginalDimensions(imgSrc) {
    var img = new Image();
    img.src = imgSrc;

    return new Promise((resolve, reject) => {
        console.log(img.width)
        img.onload = () => resolve([img.width, img.height]);
    })
}


export async function make_request(route, method, body = null) {
    const response = await requestWithAccessToken(route, method, body);


    if (response.status === 401) {
        // try fetching new access token
        console.log('fetch again')
        await fetchAccessToken();
        const secResponse = await requestWithAccessToken(route, method, body);
        if (secResponse.status === 200) {
            return secResponse.json();
        } else {
            const err = await secResponse.json();
            return Promise.reject(err.detail);
        }
    } else {
        return response.json();
    }
}

export function getMovements(event, canvas, scaleFactor) {
    const rect = canvas.getBoundingClientRect();
    const xDistance = (event.clientX - rect.left) / scaleFactor;
    const yDistance = (event.clientY - rect.top) / scaleFactor;
    return [xDistance, yDistance];
}

async function requestWithAccessToken(route, method, body = null) {
    const accessToken = localStorage.getItem("accessToken");
    const payload = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + accessToken
        },
    }
    if (body !== null) { payload.body = JSON.stringify(body); }

    return fetch(backendURL + route, payload)
        .then((res) => {
            return res
        })
    // return fetch(backendURL + route, payload);
}

export async function blacklistRefreshToken(refreshToken, accessToken){
    try {
        const payload = {
            method: "POST",
            timeout: 5000,
            headers: { 'Content-Type': 'application/json',
                       'Authorization': "Bearer " + accessToken},
            body: JSON.stringify({ 'refresh': refreshToken })
        }
        const res = await fetch(backendURL + "/api/token/blacklist/", payload);
        }
    catch (e) {
        console.log(e)
    }
}