import "./ImageHolder.scss";

export default function ImageHolder(props) {
  const url = props.image ? URL.createObjectURL(props.image) : "";

  return (
    <div className="image-container">

      <div style={{ backgroundImage: `url(${url})` }} onClick={props.onClick} className="image-holder">
        {!props.image && <h4>Upload Image</h4>}
      </div>
      <p style={{ marginTop: "0.4rem", fontSize: "0.9rem", fontWeight: "500" }}>{props.title}</p>
    </div>
  )
}