import { AnnotationPage } from './pages/annotationPage/AnnotationPage';
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LoginPage } from './pages/loginPage/LoginPage';
import { CasesPage } from './pages/casesPage/CasesPage';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import "./App.scss"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createPalette from '@mui/material/styles';
import createTypography from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import PrivateRoute from './components/privateRoute/PrivateRoute'


const theme = createTheme({
  palette: {
    primary: {
      main: '#00adbb',
      contrastText: "#fff"
    },
    secondary: {
      main: '#fff0f1'
    },
    third: {
      main: '#000000'
    },
    fourth: {
      main: '#ff0066'
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeightRegular: 500,
  },
  components: {
    Menu: {
      borderRadius: '39px',
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<LoginPage />} />
          <Route
            path='/annotation-steps/:caseId'
            element={
              <PrivateRoute>
                <AnnotationPage />
              </PrivateRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <PrivateRoute>
                <CasesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={<Navigate to="/" />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
