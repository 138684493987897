export const STEP_STATUS = {
    DONE: "done",
    IN_PROGRESS: "in-progress",
    COMING_UP: "coming-up",
}

export const ANNOTATION = {
    SINGLE_LINE: 0,
    DOUBLE_LINES: 1,
    DOUBLE_CIRCLES: 2,
    DOT: 3,
    SINGLE_CIRCLE: 4,
}

export const IMAGETYPE = {
    AP: 0,
    LST: 1,
    LSE: 2,
    FLSE: 3,
}

export function resizeCoords(step, ratio) {
    switch (step.type) {
        case ANNOTATION.DOUBLE_LINES:
            const line1 = step.annotation.line1;
            const line2 = step.annotation.line2;
            return {
                line1: [
                    { x: line1[0].x * ratio, y: line1[0].y * ratio },
                    { x: line1[1].x * ratio, y: line1[1].y * ratio },
                ],
                line2: [
                    { x: line2[0].x * ratio, y: line2[0].y * ratio },
                    { x: line2[1].x * ratio, y: line2[1].y * ratio },
                ]
            }
        case ANNOTATION.SINGLE_LINE:
            const line = step.annotation.line1;
            return {
                line1: [
                    { x: line[0].x * ratio, y: line[0].y * ratio },
                    { x: line[1].x * ratio, y: line[1].y * ratio },
                ]
            }
        case ANNOTATION.DOT:
            return {
                x: step.annotation.x * ratio,
                y: step.annotation.y * ratio
            }
        case ANNOTATION.DOUBLE_CIRCLES:
            const c1 = step.annotation.circle1;
            const c2 = step.annotation.circle2;
            return {
                circle1: { x: c1.x * ratio, y: c1.y * ratio, radius: c1.radius * ratio },
                circle2: { x: c2.x * ratio, y: c2.y * ratio, radius: c2.radius * ratio }
            }
        case ANNOTATION.SINGLE_CIRCLE:
            const c3 = step.annotation.circle1;
            return {
                circle1: { x: c3.x * ratio, y: c3.y * ratio, radius: c3.radius * ratio },
            }
        default:
            break;
    }
}

export const steps = [
    {
        type: ANNOTATION.DOUBLE_LINES,
        imageType: IMAGETYPE.AP,
        name: "Cortical Index",
    },
    {
        type: ANNOTATION.SINGLE_CIRCLE,
        imageType: IMAGETYPE.AP,
        name: "Calibration",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.AP,
        name: "Teardrops",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.AP,
        name: "Lesser Trochanters",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.LST,
        name: "S1 Endplate",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.LST,
        name: "L1 Superior Endplate",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.LST,
        name: "ASIS",
    },
    {
        type: ANNOTATION.DOT,
        imageType: IMAGETYPE.LST,
        name: "Pubic Symphysis",
    },
    {
        type: ANNOTATION.DOUBLE_CIRCLES,
        imageType: IMAGETYPE.LST,
        // annotation: {
        //     circle1: { x: 30, y: 130, radius: 18 },
        //     circle2: { x: 50, y: 50, radius: 18 }
        // },
        name: "Femoral Head Centre",
    },
    // {
    //     type: ANNOTATION.SINGLE_LINE,
    //     imageType: IMAGETYPE.LSE,
    //     name: "S1 Endplate",
    // },
    // {
    //     type: ANNOTATION.SINGLE_LINE,
    //     imageType: IMAGETYPE.LSE,
    //     name: "L1 Superior Endplate",
    // },
    // {
    //     type: ANNOTATION.SINGLE_LINE,
    //     imageType: IMAGETYPE.FLSE,
    //     name: "S1 Endplate",
    // },
    // {
    //     type: ANNOTATION.SINGLE_LINE,
    //     imageType: IMAGETYPE.FLSE,
    //     name: "L1 Superior Endplate",
    // },
];

export const lseSteps = [
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.LSE,
        name: "S1 Endplate",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.LSE,
        name: "L1 Superior Endplate",
    },
]

export const flseSteps = [
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.FLSE,
        name: "S1 Endplate",
    },
    {
        type: ANNOTATION.SINGLE_LINE,
        imageType: IMAGETYPE.FLSE,
        name: "L1 Superior Endplate",
    },
]


// export const images = [
//     {
//         src: "",
//         localWidth: 0,
//         localHeight: 0,
//         naturalWidth: 0,
//         naturalHeight: 0
//     },
//     {
//         src: "",
//         localWidth: 0,
//         localHeight: 0,
//         naturalWidth: 0,
//         naturalHeight: 0
//     },
//     {
//         src: "",
//         localWidth: 0,
//         localHeight: 0,
//         naturalWidth: 0,
//         naturalHeight: 0
//     }
// ]
