import { Typography, TextField, Select, MenuItem, Button, CircularProgress, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useMemo, useRef, useState, useEffect } from "react";
import { updateForm, make_request } from "../../helpers.js";
import ImageHolder from "../imageHolder/ImageHolder.jsx";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import "./EditCaseForm.scss";

export default function EditCaseForm(props) {
    const [firstname, setFirstname] = useState(props.caseData.firstname);
    const [lastname, setLastname] = useState(props.caseData.lastname);
    const [dateOfSurg, setDateOfSurg] = useState(props.caseData.date_of_surgery);
    const [dateOfBirth, setDateOfBirth] = useState(props.caseData.patient_dob);
    const [surgeon, setSurgeon] = useState(props.caseData.surgeon_id);
    const [surgicalApproach, setSurgicalApproach] = useState(props.caseData.surgical_approach);
    const [gender, setGender] = useState(props.caseData.gender);
    const [side, setSide] = useState(props.caseData.side_of_analysis);
    const [surgicalPosition, setSurgicalPosition] = useState(props.caseData.surgical_position);
    const [scheme, setscheme] = useState(props.caseData.scheme);
    const [approvalNo, setapprovalNo] = useState(props.caseData.approval_no);

    // progress indication
    const [loading, setLoading] = useState(false);

    // dynamic values loaded from backend
    const [surgeons, setSurgeons] = useState([]);
    const [allAppraoches, setAllAppraoches] = useState([]);

    const img1Ref = useRef(null);
    const img2Ref = useRef(null);
    const img3Ref = useRef(null);
    const img4Ref = useRef(null);
    const [img1, setImg1] = useState(null);
    const [img2, setImg2] = useState(null);
    const [img3, setImg3] = useState(null);
    const [img4, setImg4] = useState(null);
    const isSurgeon = localStorage.getItem("is_surgeon") === 'true';
    const uid = localStorage.getItem("uid");
    const fullname = localStorage.getItem("fullname");

    const images = useMemo(() => <div className='form-row'>
        <ImageHolder title="AP" image={img1} onClick={() => img1Ref.current.click()} />
        <ImageHolder title="Standing Lateral" image={img2} onClick={() => img2Ref.current.click()} />
        <ImageHolder title="Relax Seated Lateral" image={img3} onClick={() => img3Ref.current.click()} />
        <ImageHolder title="Flex Seated Lateral" image={img4} onClick={() => img4Ref.current.click()} />
    </div>, [img1, img2, img3, img4]);

    useEffect(() => {
        getSurgeons();
        getSurgicalApproaches();
    }, []);

    const updateCase = async () => {
        const payload = {
            firstname: firstname,
            lastname: lastname,
            dateOfBirth: dateOfBirth,
            gender: gender,
            sideOfAnalysis: side,
            surgicalApproach: surgicalApproach,
            surgicalPosition: surgicalPosition,
            dateOfSurgery: dateOfSurg,
            surgeon: surgeon,
            scheme: scheme,
            approval_no: approvalNo,
        }

        // if (img1) {
        //     payload["xrayAp"] = img1;
        // }
        
        // if (img2) {
        //     payload["xrayLst"] = img2;
        // }

        // if (img3) {
        //     payload["xrayLse"] = img3;
        // }

        // if (img4) {
        //     payload["xrayFlse"] = img4;
        // }

        setLoading(true);
        const res = await updateForm(payload, props.caseData.id);
        setLoading(false);
        console.log(res.status);
        if (res.status === 201 || res.status === 200){
            props.closeDialog(true);
        } else{
            toast.warning("Fail to update the case");
        }
    }

    const getSurgeons = async () => {
        if (isSurgeon){
            setSurgeon(uid)
        } else {
            const allSurgeons = await make_request("/api/user/surgeons/", "GET");
            setSurgeons(allSurgeons);
            if (allSurgeons.length > 0) {
                setSurgeon(allSurgeons[0].id)
            } else {
                alert("No surgeons available");
            }
        }
    }

    const getSurgicalApproaches = async () => {
        const approaches = await make_request("/api/surgicalapproach/", "GET");
        setAllAppraoches(approaches);
        // setSurgicalApproach(approaches[0].name);
    }

    const selectStyles = {
        MenuProps:{
            maxHeight: '15rem'
        } 
      };

    return <div>
        {/* <input ref={img1Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg1(e.target.files[0])} />
        <input ref={img2Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg2(e.target.files[0])} />
        <input ref={img3Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg3(e.target.files[0])} />
        <input ref={img4Ref} style={{ display: "none" }} type="file" onChange={(e) => setImg4(e.target.files[0])} /> */}
        <form id='edit-case-form'>
            <div>
                <h1 style={{fontSize:"1.7rem"}}>Case Details</h1>
                <h3 style={{fontWeight:300}}></h3>
                <br></br>
            </div>
            {loading
                ? <CircularProgress />
                : <>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>First Name</h2>
                            <TextField value={firstname} fullWidth={true} size="small" onChange={(e) => setFirstname(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Last Name</h2>
                            <TextField value={lastname} fullWidth={true} size="small" onChange={(e) => setLastname(e.target.value)} />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Date of Birth</h2>
                            <TextField value={dateOfBirth} fullWidth={true} type="date" size="small" onChange={(e) => setDateOfBirth(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Gender</h2>
                            <Select
                                fullWidth={true}
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="M">Male</MenuItem>
                                <MenuItem value="F">Female</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Date of Surgery</h2>
                            <TextField value={dateOfSurg} fullWidth={true} type="date" size="small" onChange={(e) => setDateOfSurg(e.target.value)} />
                        </div>
                        <div className='field-holder'>
                            <h2>Surgeon</h2>
                            <Select
                                value={surgeon}
                                fullWidth={true}
                                onChange={(e) => setSurgeon(e.target.value)}
                                size="small"
                                MenuProps={{
                                    style: {
                                       maxHeight: '20rem',
                                          },
                                    }}
                            >
                                {isSurgeon ? (
                                    <MenuItem key={uid} value={uid}>{fullname}</MenuItem>
                                ) : (
                                    surgeons.map(s => <MenuItem key={s.id} value={s.id}>{s.full_name}</MenuItem>)
                                )}
                            </Select>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Surgical Approach</h2>
                            <Select
                                fullWidth={true}
                                value={surgicalApproach}
                                onChange={(e) => setSurgicalApproach(e.target.value)}
                                size="small"
                            >
                                {allAppraoches.map((approach) => <MenuItem key={approach.name} value={approach.name}>{approach.name}</MenuItem>)}
                            </Select>
                        </div>
                        <div className='field-divider'>
                            <div className='field-holder'>
                                <h2>Surgical Position</h2>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        style={{height:"2.4rem"}}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={surgicalPosition}
                                        onChange={(event) => setSurgicalPosition(event.target.value)}
                                    >
                                        <FormControlLabel value="S" control={<Radio color='fourth' />} label={<Typography fontSize={"0.9rem"} fontWeight={surgicalPosition === "S" ? 600 : 500} color={surgicalPosition === "S" ? "black" : "grey"}>SUPINE</Typography>} />
                                        <FormControlLabel value="L" control={<Radio color= 'primary' />} label={<Typography fontSize={"0.9rem"} fontWeight={surgicalPosition === "L" ? 600 : 500} color={surgicalPosition === "L" ? "black" : "grey"}>LATERAL</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className='field-holder less'>
                                <h2>Side of Analysis</h2>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        style={{height:"2.4rem"}}
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={side}
                                        onChange={(event) => setSide(event.target.value)}
                                    >
                                        <FormControlLabel value="L" control={<Radio color='third' />} label={<Typography fontSize={"0.9rem"} fontWeight={side === "L" ? 600 : 500} color={side === "L" ? "black" : "grey"}>LEFT</Typography>} />
                                        <FormControlLabel value="R" control={<Radio color='third' />} label={<Typography fontSize={"0.9rem"} fontWeight={side === "R" ? 600 : 500} color={side === "R" ? "black" : "grey"}>RIGHT</Typography>} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='field-holder'>
                            <h2>Approval Scheme</h2>
                            <FormControl>
                                <RadioGroup
                                    row
                                    fullWidth
                                    style={{height:"2.4rem"}}
                                    aria-labelledby="radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={scheme}
                                    onChange={(event) => setscheme(event.target.value)}
                                >
                                    <FormControlLabel value="S" control={<Radio color='third' />} label={<Typography fontSize={"0.9rem"} fontWeight={scheme === "S" ? 600 : 500} color={scheme === "S" ? "black" : "grey"}>Special Access</Typography>} />
                                    <FormControlLabel value="A" control={<Radio color= 'third' />} label={<Typography fontSize={"0.9rem"} fontWeight={scheme === "A" ? 600 : 500} color={scheme === "A" ? "black" : "grey"}>Authorised Prescriber</Typography>} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className='field-holder'>
                            <h2>Approval No.</h2>
                            <TextField value={approvalNo} fullWidth={true} size="small" onChange={(e) => setapprovalNo(e.target.value)} />
                        </div>
                    </div>
                    <AiOutlineCloseCircle onClick={() => props.closeDialog(false)} size={'1.8rem'} className='close-form-button' />
                    {/* {images} */}
                </>
            }
            <Button sx={{ width: '10rem'}} variant="contained" disabled={loading} onClick={updateCase}>Update</Button>
        </form>
    </div>
}
