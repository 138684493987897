import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from "react";
import { make_request, versionNo } from "../../helpers.js";
import { Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from '@mui/material';
import CaseBar from "../../components/caseBar/CaseBar.jsx";
import "./CasesPage.scss";
import { useNavigate } from 'react-router-dom';
import CreateCaseForm from "../../components/createCaseForm/CreateCaseForm.jsx";
import EditCaseForm from "../../components/editCaseForm/EditCaseForm.jsx";
import { MdOutlineAdd } from 'react-icons/md';
import navbit from "../../assets/navbit_only_black.svg";
import whitelogo from "../../assets/navbit_logo_only_white.svg";
import { TopBar } from "../../components/headerBar/TopBar.jsx";
import { toast } from "react-toastify";
import UploadingTemplatingForm from "../../components/uploadTemplatingForm/UploadTemplatingForm.jsx";
import { Link } from "react-router-dom";

const casePerPage = 8;
const casesEachFetch = 10;

function ConfirmationDialog({ open, onClose, onConfirm }) {
    if (!open) {
      return null;
    }
  
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you the prescriber?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

export function CasesPage(props) {
    const [loading, setLoading] = useState(false);
    const [cases, setCases] = useState([]);
    const [totalCases, setTotalCases] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [createCaseOpen, setCreateCaseOpen] = useState(false);
    const [uploadTemplatingOpen, setUploadTemplatingOpen] = useState(props.upload);
    const [CaseEditOpen, setCaseEditOpen] = useState(props.edit);
    const [currentCase, setCurrentCase] = useState(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    // get first 10 cases when entering
    useEffect(() => {
        fetchStartingCases(true);
    }, [])

    useEffect(() => {
        setUploadTemplatingOpen(props.upload);
    }, [props.upload])

    useEffect(() => {
        setCaseEditOpen(props.edit);
    }, [props.edit])

    const fetchStartingCases = async (first) => {
        const cases = await make_request("/api/case/", "GET");
        setLoading(false);
        if (first){
            setPageIndex(0);
        }
        setCases(cases.results);
        setTotalCases(cases.count);
    }

    const currPageCases = () => {
        const currCases = cases;
        return currCases.map(c => <CaseBar
            key={c.id}
            data={c}
            openTemplatingDialog={() => setUploadTemplatingOpen(true)}
            openEditCaseDialog={(casedata) => {
                setCurrentCase(casedata);
                setCaseEditOpen(true);}}
        />)
    }

    const handleChangePage = async (_, newPage) => {
        const res = await make_request(`/api/case/?page=${newPage}`, "GET");
        if ("results" in res) {
            setCases(res.results);
            setPageIndex(newPage - 1);
        } else {
            toast.warning("Fail to load more cases");
        }
    };

    const openConfirmationDialog = () => {
        setConfirmationOpen(true);
    };

    const closeConfirmationDialog = () => {
        setConfirmationOpen(false);
    };

    const handleConfirmCreateCase = () => {
        closeConfirmationDialog();
        setCreateCaseOpen(true);
    };

    const closeCreateFormDialog = (success) => {
        setCreateCaseOpen(false);
        if (success) {
            handleChangePage(null,pageIndex + 1);
            toast.success("Case created successfully");
        }
    }

    const closeTemplateFormDialog = (success) => {
        setUploadTemplatingOpen(false)
        if (success) {
            handleChangePage(null,pageIndex + 1);
            toast.success("Templating updated successfully");
        }
    }

    const closeEditFormDialog = (success) => {
        setCaseEditOpen(false)
        if (success) {
            handleChangePage(null,pageIndex + 1);
            toast.success("Case updated successfully");
        }
    }

    return <div className="case-page" id="cases-page">
        {/* <img src={whitelogo} id="white-logo" ></img> */}
        
        <div id="dashboard-header-row">
            <a href="/dashboard">
                <img src={navbit} alt="" id="navbit-title" />
            </a>
            <TopBar/>
        </div>
        <div id="add-new-case" onClick={openConfirmationDialog}>
            <MdOutlineAdd size={"2rem"} id="add-case-icon" />
            <p style={{fontSize: '1.6rem', marginLeft: '0.2rem'}}>Create Case</p>
        </div>
        <div id="cases-card">
            {loading
                ? <CircularProgress />
                : <div id="case-list">
                    <div id='case-bar-title-wrapper'>
                        <div id="case-bar-title">
                            <h2>PATIENT NAME</h2>
                            <h2>SIDE</h2>
                            <h2>DATE OF SURGERY</h2>
                            <h2>CASE ID</h2>
                            <h2>STATUS</h2>
                            <h2>DATE CREATED</h2>
                        </div>
                    </div>
                    {[...currPageCases()]}
                </div>
            }
        </div>
        <div id="pagination-container">
            <Pagination onChange={handleChangePage} size="medium" count={Math.ceil(totalCases / casePerPage)} color="primary" />
        </div>
        <div class="misc-info">Software version: {versionNo}</div>
        
        <ConfirmationDialog
                open={confirmationOpen}
                onClose={closeConfirmationDialog}
                onConfirm={handleConfirmCreateCase}
            />

        <Dialog fullWidth={true} maxWidth="md" open={createCaseOpen}>
            <CreateCaseForm closeDialog={closeCreateFormDialog} />
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={uploadTemplatingOpen}>
            <UploadingTemplatingForm closeDialog={closeTemplateFormDialog} />
        </Dialog>
        <Dialog fullWidth={true} maxWidth="md" open={CaseEditOpen}>
            <EditCaseForm caseData={currentCase} closeDialog={closeEditFormDialog} />
        </Dialog>
    </div>

}