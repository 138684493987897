import "./AnnotationPage.scss";
import { IoMdRefresh } from 'react-icons/io';
import { AnnotationStep } from "../../components/annotationStep/AnnotationStep";
import { useState, Fragment, useRef, useEffect } from "react";
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, TextField } from "@mui/material";
import { steps, ANNOTATION, STEP_STATUS, IMAGETYPE, lseSteps, flseSteps } from "../../assets/stepsData.js";
import styled, { css } from 'styled-components';
import PageWrapper from "../pageWrapper/PageWrapper";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchImage, make_request, getImageOriginalDimensions } from "../../helpers";
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

// annotation options
import { AnnotateDoubleLines } from "../../components/annotateDoubleLines/AnnotateDoubleLines";
import { AnnotateSingleLine } from "../../components/annotateSingleLine/AnnotateSingleLine";
import { AnnotateDoubleCircles } from "../../components/annotateDoubleCircles/AnnotateDoubleCircles";
import { AnnotateSingleCircle } from "../../components/annotateSingleCircle/AnnotateSingleCircle";
import { AnnotateDot } from "../../components/annotateDot/AnnotateDot";
import { useLayoutEffect } from "react";
import { toast } from "react-toastify";

const AnnotationBackground =
    styled.div.attrs(props => ({
        style: {
            transform: `scale(${props.scale})`,
            top: props.top + "px",
            left: props.left + "px",
            width: props.width,
            height: props.height
        },
    }))`
    background-color: white;
    position: relative;
    display: flex;
`

const Annotation = styled.div`
    position: relative;
    overflow: hidden;
    width: 50%;
    display: flex;
    background-color: #454545;
    align-items: center;
    justify-content: center;
    cursor: grab;
    cursor: -webkit-grab;

    ${props => props.panning === true && css`
        cursor:  grabbing;
        cursor: -webkit-grabbing;
    `}
`

const imageTypeToIndex = {
    [IMAGETYPE.AP]: 0,
    [IMAGETYPE.LST]: 1,
    [IMAGETYPE.LSE]: 2,
    [IMAGETYPE.FLSE]: 3,
}

export function AnnotationPage() {
    // global variables
    const [stepsData, setStepsData] = useState(null);
    const [imagesData, setImagesData] = useState(null);

    const [loading, setLoading] = useState(true);
    const defaultStepsDataRef = useRef(null);

    // url related
    const { caseId } = useParams();
    const navigator = useNavigate();
    const [searchParams] = useSearchParams();
    const startingStep = searchParams.get('startingStep'); // "testCode"

    // set the starting step index
    const [stepIndex, setStepIndex] = useState(startingStep ? parseInt(startingStep) : 0);

    // annotate canvas related
    const [scaleFactor, setScaleFactor] = useState(1);
    const [dimensions, setDimensions] = useState([0, 0]);
    const annotateSection = useRef(null);
    const annotateClip = useRef(null);

    // scaling and panning
    const [offsets, setOffsets] = useState([0, 0]);
    const [panning, setPanning] = useState(false);

    // for calibration step
    const [dialogOpen, setDialogOpen] = useState(false);
    const [objectSize, setobjectSize] = useState(null);


    useEffect(() => {
        if (imagesData && stepsData) {
            const currStep = stepsData[stepIndex];
            const index = imageTypeToIndex[currStep.imageType];
            setDimensions([imagesData[index].localWidth, imagesData[index].localHeight]);
            setScaleFactor(1);
            setOffsets([0, 0]);
        }
    }, [imagesData, stepIndex])

    useLayoutEffect(() => {
        fetchAnnotateData();
    }, [])

    const getImageLocalDimensions = (naturalWidth, naturalHeight) => {
        const container = annotateSection.current.getBoundingClientRect();
        if (container.height / container.width < naturalHeight / naturalWidth) {
            return [container.height * (naturalWidth / naturalHeight), container.height];
        } else {
            return [container.width, container.width * (naturalHeight / naturalWidth)];
        }
    }

    const fetchAnnotateData = async () => {
        const data = await make_request('/api/case/' + caseId, 'GET');

        const newImagesData = Array(4);
        let hasLse = false;
        let hasFlse = false;

        // get xray_ap image
        const ap = await fetchImage(data.imagingdata.xray_ap);
        const apNatural = await getImageOriginalDimensions(ap);
        const apLocal = getImageLocalDimensions(apNatural[0], apNatural[1]);
        newImagesData[0] = { src: ap, localWidth: apLocal[0], localHeight: apLocal[1], naturalWidth: apNatural[0], naturalHeight: apNatural[1] };

        // newImagesData.push(
        //     { src: ap, localWidth: apLocal[0], localHeight: apLocal[1], naturalWidth: apNatural[0], naturalHeight: apNatural[1] }
        // );

        // get xray_lst image
        const lst = await fetchImage(data.imagingdata.xray_lst);
        const lstNatural = await getImageOriginalDimensions(lst);
        const lstLocal = getImageLocalDimensions(lstNatural[0], lstNatural[1]);
        newImagesData[1] = { src: lst, localWidth: lstLocal[0], localHeight: lstLocal[1], naturalWidth: lstNatural[0], naturalHeight: lstNatural[1] };
        // newImagesData.push(
        //     { src: lst, localWidth: lstLocal[0], localHeight: lstLocal[1], naturalWidth: lstNatural[0], naturalHeight: lstNatural[1] }
        // )

        // get xray_lse image: optional
        if (data.imagingdata.xray_lse) {
            const lse = await fetchImage(data.imagingdata.xray_lse);
            const lseNatural = await getImageOriginalDimensions(lse);
            const lseLocal = getImageLocalDimensions(lseNatural[0], lseNatural[1]);
            newImagesData[2] = { src: lse, localWidth: lseLocal[0], localHeight: lseLocal[1], naturalWidth: lseNatural[0], naturalHeight: lseNatural[1] };
            // newImagesData.push(
            //     { src: lse, localWidth: lseLocal[0], localHeight: lseLocal[1], naturalWidth: lseNatural[0], naturalHeight: lseNatural[1] }
            // );
            hasLse = true;
        }

        // get xray_lse image: optional
        if (data.imagingdata.xray_lfse) {
            const flse = await fetchImage(data.imagingdata.xray_lfse);
            const flseNatural = await getImageOriginalDimensions(flse);
            const flseLocal = getImageLocalDimensions(flseNatural[0], flseNatural[1]);
            newImagesData[3] = { src: flse, localWidth: flseLocal[0], localHeight: flseLocal[1], naturalWidth: flseNatural[0], naturalHeight: flseNatural[1] };
            // newImagesData.push(
            //     { src: flse, localWidth: flseLocal[0], localHeight: flseLocal[1], naturalWidth: flseNatural[0], naturalHeight: flseNatural[1] }
            // );
            hasFlse = true;
        }

        // init imagesData
        // const newImagesData = Array(4);
        // newImagesData[0] = { src: ap, localWidth: apLocal[0], localHeight: apLocal[1], naturalWidth: apNatural[0], naturalHeight: apNatural[1] };
        // newImagesData[1] = { src: lst, localWidth: lstLocal[0], localHeight: lstLocal[1], naturalWidth: lstNatural[0], naturalHeight: lstNatural[1] };
        // newImagesData[2] = { src: lse, localWidth: lseLocal[0], localHeight: lseLocal[1], naturalWidth: lseNatural[0], naturalHeight: lseNatural[1] };

        // init stepsData
        let landMarks;
        if (data.planning_status === 0) {
            landMarks = initStepsData(data.defaultlandmarks, hasLse, hasFlse);
        } else {
            landMarks = initStepsData(data.landmarkselections, hasLse, hasFlse);
        }
        scaleCoords(false, landMarks, newImagesData)

        // memorize default annotation for step-reset purpose
        defaultStepsDataRef.current = JSON.parse(JSON.stringify(landMarks));

        setStepsData(landMarks);
        setImagesData(newImagesData);
        setLoading(false);
    }

    const putAnnotateData = async () => {
        scaleCoords(true, stepsData, imagesData);
        const payload = {
            cortical_coords: stepsData[0].data.join(","),
            ap_cali_coords: stepsData[1].data.slice(0, 2).join(","),
            ap_cali_radius: stepsData[1].data[2],
            ap_physical_radius: objectSize,
            ap_td_coords: stepsData[2].data.join(","),
            ap_lt_coords: stepsData[3].data.join(","),
            st_s1_coords: stepsData[4].data.join(","),
            st_l1_coords: stepsData[5].data.join(","),
            st_asis_coords: stepsData[6].data.join(","),
            st_ps_coord: stepsData[7].data.join(","),
            st_h1_coord: stepsData[8].data.slice(0, 2).join(","),
            st_h1_radius: stepsData[8].data[2],
            st_h2_coord: stepsData[8].data.slice(3, 5).join(","),
            st_h2_radius: stepsData[8].data[5],
            se_s1_coords: "",
            se_l1_coords: "",
            fse_s1_coords: "",
            fse_l1_coords: "",
        }

        // add optional data
        if (stepsData.length > 9) {
            if (stepsData[9].imageType === IMAGETYPE.LSE) {
                payload.se_s1_coords = stepsData[9].data.join(",");
                payload.se_l1_coords = stepsData[10].data.join(",");
            } else {
                payload.fse_s1_coords = stepsData[9].data.join(",");
                payload.fse_l1_coords = stepsData[10].data.join(",");
            }
            if (stepsData.length > 11) {
                payload.fse_s1_coords = stepsData[11].data.join(",");
                payload.fse_l1_coords = stepsData[12].data.join(",");
            }
        }

        setLoading(true);
        const res = await make_request(`/api/case/${caseId}/landmark-selections`, 'PUT', payload);
        setLoading(false);
        navigator('/dashboard');
        toast.success("Annotation submitted successfully");
    }

    const scaleCoords = (up, steps, imagesData) => {
        const convertWithRatio = (s, ratio) => s.data.map(c => Math.round(c * ratio));
        for (let step of steps) {
            const index = imageTypeToIndex[step.imageType];
            step.data = convertWithRatio(step, up
                ? imagesData[index].naturalWidth / imagesData[index].localWidth
                : imagesData[index].localWidth / imagesData[index].naturalWidth
            );
        }
    }

    const initStepsData = (landmarks, hasLse, hasFlse) => {
        const parseCoords = (str) => str.split(',').map(s => parseInt(s));

        // default
        setobjectSize(landmarks.ap_physical_radius)
        let newStepsData = steps;
        newStepsData[0].data = parseCoords(landmarks.cortical_coords);
        newStepsData[1].data = [...parseCoords(landmarks.ap_cali_coords), landmarks.ap_cali_radius];
        newStepsData[2].data = parseCoords(landmarks.ap_td_coords);
        newStepsData[3].data = parseCoords(landmarks.ap_lt_coords);
        newStepsData[4].data = parseCoords(landmarks.st_s1_coords);
        newStepsData[5].data = parseCoords(landmarks.st_l1_coords);
        newStepsData[6].data = parseCoords(landmarks.st_asis_coords);
        newStepsData[7].data = parseCoords(landmarks.st_ps_coord);
        newStepsData[8].data = [...parseCoords(landmarks.st_h1_coord), landmarks.st_h1_radius, ...parseCoords(landmarks.st_h2_coord), landmarks.st_h2_radius];

        // optional: lse
        if (hasLse) {
            newStepsData = newStepsData.concat(lseSteps);
            newStepsData[9].data = parseCoords(landmarks.se_s1_coords);
            newStepsData[10].data = parseCoords(landmarks.se_l1_coords);
        }

        // optional: flse
        if (hasFlse) {
            if (!hasLse){
                newStepsData = newStepsData.concat(flseSteps);
                newStepsData[9].data = parseCoords(landmarks.fse_s1_coords);
                newStepsData[10].data = parseCoords(landmarks.fse_l1_coords);
            } else {
                newStepsData = newStepsData.concat(flseSteps);
                newStepsData[11].data = parseCoords(landmarks.fse_s1_coords);
                newStepsData[12].data = parseCoords(landmarks.fse_l1_coords);
            }
        }
        // generate unique ID for each step
        for (const s of newStepsData) {
            s.id = uuid();
        }

        return newStepsData;
    }

    const nextStep = () => {
        if (stepIndex >= stepsData.length - 1) {
          putAnnotateData();
        } else {
          if (stepIndex === 1) {
            setDialogOpen(true);
          } else {
            setStepIndex(stepIndex + 1);
          }
        }
    };
    
    const closeDialog = () => {
        setDialogOpen(false);
        console.log(objectSize)
        if (isNaN(objectSize) || objectSize === '') {
          setStepIndex(stepIndex + 3); // Skip the next step
        } else {
          setStepIndex(stepIndex + 1);
        }
      };

    const resetAnnotation = () => {
        const currStepDefault = [...stepsData];
        const defaultClone = JSON.parse(JSON.stringify(defaultStepsDataRef.current));
        currStepDefault[stepIndex] = defaultClone[stepIndex];
        currStepDefault[stepIndex].id = uuid();
        setStepsData(currStepDefault);
    }

    const gotoStep = (stepToGo) => {
        if (stepToGo < stepIndex) {
            setStepIndex(stepToGo);
        }
    }

    const renderSteps = () => {
        return <Fragment>
            {stepsData && stepsData.map((step, index) => {
                let status = STEP_STATUS.DONE;
                if (index >= stepIndex) {
                    status = index === stepIndex ? STEP_STATUS.IN_PROGRESS : STEP_STATUS.COMING_UP;
                }
                return <AnnotationStep onClick={() => gotoStep(index)} key={index} status={status}>
                    {step.name}
                </AnnotationStep>
            })}
        </Fragment>
    }

    const renderAnnotation = () => {
        const data = stepsData[stepIndex];

        // callback to be called within each different annotation
        const confirm = (newAnnotation) => {
            stepsData[stepIndex].data = newAnnotation;
        }

        const stepId = data.id ? data.id : stepIndex;

        // const dimensions = [getCurrBackgroundImage().localWidth, getCurrBackgroundImage().localHeight];
        switch (data.type) {
            case ANNOTATION.SINGLE_LINE:
                return <AnnotateSingleLine
                    confirmCallback={confirm}
                    key={stepId}
                    dimensions={dimensions}
                    calcZoom={getZoomFactor}
                    coords={data.data}
                />
            case ANNOTATION.DOUBLE_LINES:
                return <AnnotateDoubleLines
                    confirmCallback={confirm}
                    key={stepId}
                    dimensions={dimensions}
                    calcZoom={getZoomFactor}
                    coords={data.data}
                />
            case ANNOTATION.DOUBLE_CIRCLES:
                return <AnnotateDoubleCircles
                    confirmCallback={confirm}
                    key={stepId}
                    dimensions={dimensions}
                    calcZoom={getZoomFactor}
                    c1={data.data.slice(0, 3)}
                    c2={data.data.slice(3, 6)}
                />
            case ANNOTATION.DOT:
                return <AnnotateDot
                    confirmCallback={confirm}
                    key={stepId}
                    dimensions={dimensions}
                    calcZoom={getZoomFactor}
                    dot={data.data}
                />
            case ANNOTATION.SINGLE_CIRCLE:
                return <AnnotateSingleCircle
                    confirmCallback={confirm}
                    key={stepId}
                    dimensions={dimensions}
                    calcZoom={getZoomFactor}
                    c1={data.data}
                />
            default:
                break;
        }
    }

    const zoomImage = (event) => {
        const factor = getZoomFactor(event);
        setOffsets(calcFinalOffsets(0, 0, factor));
        setScaleFactor(factor);
    }

    const getZoomFactor = (event) => {
        const change = event.deltaY * 0.001;
        return Math.min(Math.max(scaleFactor - change, 1), 5);
    }

    const calcFinalOffsets = (movementX, movementY, zoomFactor = scaleFactor) => {
        const xLimit = (zoomFactor - 1) * dimensions[0] / 2;
        const yLimit = (zoomFactor - 1) * dimensions[1] / 2;
        const finalX = Math.max(xLimit * -1, Math.min(xLimit, offsets[0] + movementX));
        const finalY = Math.max(yLimit * -1, Math.min(yLimit, offsets[1] + movementY));
        return [finalX, finalY];
    }

    const pan = (event) => {
        const magnifier = 2;
        const calc = calcFinalOffsets(event.movementX * magnifier, event.movementY * magnifier);
        if (panning) {
            setOffsets(calc);
        }
    }

    const getCurrBackgroundImage = () => {
        const currStep = stepsData[stepIndex];
        const index = imageTypeToIndex[currStep.imageType];
        return imagesData[index]
    }

    // return <div id="annotation-page">
    //     <div id="annotation-steps">
    //         <ul>
    //             {renderSteps()}
    //         </ul>
    //         <div id="annotation-step-actions">
    //             <IoMdRefresh id="annotation-reset" onClick={resetAnnotation} />
    //             <Button variant="contained" onClick={nextStep}>Confirm</Button>
    //         </div>
    //     </div>
    //     <Annotation
    //         onMouseUp={() => setPanning(false)}
    //         onMouseDown={() => setPanning(true)}
    //         onMouseMove={pan}
    //         ref={annotateSection}
    //         panning={panning}
    //     >
    //         {imagesData && stepsData && <AnnotationBackground
    //             ref={annotateClip}
    //             onWheel={zoomImage}
    //             id="annotate-reference"
    //             scale={scaleFactor}
    //             left={offsets[0]}
    //             top={offsets[1]}
    //             width={dimensions[0]}
    //             height={dimensions[1]}
    //         >
    //             <img
    //                 src={getCurrBackgroundImage().src}
    //                 style={{
    //                     width: dimensions[0],
    //                     height: dimensions[1],
    //                 }}
    //             />
    //             {renderAnnotation()}
    //         </AnnotationBackground>}
    //         {loading && <div id="loading-canvas">
    //             <CircularProgress />
    //         </div>}
    //     </Annotation>
    // </div>
    return (
        <div id="annotation-page">
          <div id="annotation-steps">
            <ul>{renderSteps()}</ul>
            <div id="annotation-step-actions">
              <IoMdRefresh id="annotation-reset" onClick={resetAnnotation} />
              <Button variant="contained" onClick={nextStep}>
                Confirm
              </Button>
            </div>
          </div>
          <Annotation
            onMouseUp={() => setPanning(false)}
            onMouseDown={() => setPanning(true)}
            onMouseMove={pan}
            ref={annotateSection}
            panning={panning}
          >
            {imagesData && stepsData && (
              <AnnotationBackground
                ref={annotateClip}
                onWheel={zoomImage}
                id="annotate-reference"
                scale={scaleFactor}
                left={offsets[0]}
                top={offsets[1]}
                width={dimensions[0]}
                height={dimensions[1]}
              >
                <img
                  src={getCurrBackgroundImage().src}
                  style={{
                    width: dimensions[0],
                    height: dimensions[1],
                  }}
                />
                {renderAnnotation()}
              </AnnotationBackground>
            )}
            {loading && (
              <div id="loading-canvas">
                <CircularProgress />
              </div>
            )}
          </Annotation>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogContent>
              <DialogContentText>
                Object physical dimension:
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="mm"
                type="number"
                fullWidth
                size="small"
                value={objectSize}
                onChange={(e) => setobjectSize(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}