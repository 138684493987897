import "./TopBar.scss";
import { useEffect, useState } from "react";
import { Menu, MenuItem, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { clearLoginInfo, make_request, blacklistRefreshToken } from "../../helpers";
import { AiOutlineMail } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { VerticalAlignCenter } from "@mui/icons-material";
import ChangePasswordForm from "../../components/changePassword/ChangePassword.jsx";

export function TopBar(props) {
  const [changePwdOpen, setChangePwdOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [name, setName] = useState("User Name");
  const [initial, setInitial] = useState("N");
  const nav = useNavigate();

  useEffect(() => {
    getName();
  }, [])

  const getName = () => {
    const title = localStorage.getItem('title')
    const first_name = localStorage.getItem('first_name')
    const last_name = localStorage.getItem('last_name')
    const initial = localStorage.getItem('initial')
    setName(`${title} ${first_name} ${last_name}`);
    setInitial(initial)
  }

  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const logout = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");
    await blacklistRefreshToken(refreshToken, accessToken);
    clearLoginInfo();
    nav("/");
    toast.info("You are logged out");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeChangePwdFormDialog = () => {
    setChangePwdOpen(false);
  }

  return <div id="dashboard-top-bar">
    <div id="email-button" style={{verticalAlign:'bottom'}}>  
      <a href="mailto:surgicalplanning@navbit.com?subject=Surgical%20Planning%20Enquiry" style={{color:'black'}}>
        <AiOutlineMail id="email-button" size={"1.5rem"}  />
      </a> 
    </div>
    <a href="" style={{color:'black'}}>
      <FiSearch id="search-button" size={"1.5rem"} />
    </a>
    <div id="top-bar-right">
      <h3>{name}</h3>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          sx={{ width: '3rem', height: '3rem', ml: '0.5rem', mr: '0.75rem'}}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: '2.5rem', height: '2.5rem', fontSize: '1.8rem', backgroundColor: '#00adbb'}}>{initial}</Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
          transform: 'translateX(1.8rem) translateY(0%)',
          },
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 0.2rem 0.5rem rgba(0,0,0,0.22))',
            mt: '0.9rem',
            borderRadius: '0.5rem',
            '& .MuiAvatar-root': {
              width: '2rem',
              height: '2rem',
              ml: '-0.2rem',
              mr: '1rem',
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '2.8rem',
              width: '1.2rem',
              height: '1.2rem',
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
            '& .MuiListItemIcon-root': {
              fontSize: '2.2rem',
              ml: '-0.3rem',
              mr: '0.8rem',
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => setChangePwdOpen(true)}>
          <Avatar /> My Account
        </MenuItem>
        <Divider />
        <MenuItem disabled onClick={handleClose}>
          <ListItemIcon>
            <Settings/>
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={function(event){ handleClose(); logout()}}>
          <ListItemIcon>
            <Logout/>
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
    <Dialog maxWidth="md" open={changePwdOpen} onClose={() => setChangePwdOpen(false)}>
            <ChangePasswordForm closeDialog={closeChangePwdFormDialog} />
    </Dialog>
  </div>
}