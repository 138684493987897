import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { toast } from 'react-toastify';
import { make_request } from "../../helpers.js";

export default function PrivateRoute({ children }) {
    let auth = localStorage.getItem('refreshToken')
    if (!auth) {
        toast.error("Access Denied: You must be logged in to access this page.", {
            toastId: 'fail1',
        });
    }
    return auth ? <>{children}</> : <Navigate to="/" />;
  }