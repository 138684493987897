import { useEffect } from "react";
import { useRef, useState } from "react";
import { populateDoubleCircles } from "../../assets/stepsData";
import { getMovements } from "../../helpers";
import { Svg } from "../../styled-components/Svg";
import { Circle } from "../svgs/Circle";

export function AnnotateSingleCircle(props) {
  const drawboard = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);

  const [selected, setSelected] = useState(null);

  // the three geometry
  const [c1, setC1] = useState(props.c1);

  const circlesRef = useRef({
    c1: props.c1,
  })

  const changeZoom = (event) => {
    const zoomResult = props.calcZoom(event);
    setScaleFactor(zoomResult);
  }

  const selectCircle = (which) => {
    if (which === selected) {
      setSelected(null);
    } else {
      setSelected(which);
    }
  }

  const update = (c1) => {
    circlesRef.current.c1 = c1;
    props.confirmCallback(c1);
  }

  return (
    <Svg
      onWheel={changeZoom}
      ref={drawboard}
      viewBox={`0 0 ${props.dimensions[0]} ${props.dimensions[1]}`}
    >
      <Circle
        selected={selected === "c1"}
        circle={[c1[0], c1[1]]}
        radius={c1[2]}
        scale={scaleFactor}
        canvasRef={drawboard}
        drag={setC1}
        update={(circle) => {
          update(circle);
        }}
        onClick={() => selectCircle("c1")}
      />
    </Svg>
  )
}